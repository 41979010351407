import { useEffect, useReducer, useRef } from 'react';
import { createPortal } from 'react-dom';
import { StaticTree } from '~/components/Tree/Tree';
import { type DriveFile } from '~/utils/DriveAPI';
import { getId } from '~/utils/utils';

export const TreeMacro = ({
  wiki,
  file,
  files,
  content,
  query,
  depth = 1,
}: {
  wiki: DriveFile;
  file: DriveFile;
  parent: string;
  files: DriveFile[];
  content: string;
  query: string;
  depth?: number;
}) => {
  // When content changes force a re-render
  // createPortal has an issue where it won't attach to the dom when the html changes
  // If the content changes, we have a macro and there is no child node in the portal node, force an update
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const contentRef = useRef<string>(content);
  useEffect(() => {
    if (!content) return;
    const nodes = Array.from(document.querySelectorAll(query));
    const emptyChildren = nodes.filter((node) => !node.hasChildNodes());
    if (nodes.length && emptyChildren.length && contentRef.current !== content) {
      forceUpdate();
    }
    contentRef.current = content;
  }, [content, query]);

  const nodes = Array.from(document.querySelectorAll(query));
  if (!nodes.length) return null;
  return nodes.map((node) =>
    createPortal(<StaticTree wiki={wiki} files={files} currentParentId={getId(file)} depth={depth} />, node)
  );
};
