import { type DriveMimeType, type ExportMimeType } from './DriveAPI';
import gup from './gup.ts';

export const isBun = 'Bun' in globalThis;
export const hasWindow = typeof window !== 'undefined';
export const host = hasWindow ? window.location.hostname : '';
export const protocol = hasWindow ? window.location.protocol : '';
const port = hasWindow ? window.location.port : '';
export const siteURL = typeof window === 'undefined' ? '' : `${protocol}//${host}${port ? `:${port}` : ''}`;
export const apiKey = ['localhost', 'youneedawiki.dev', 'qa.youneedawiki.dev'].includes(host)
  ? 'AIzaSyDcEOk_b_K82NhcmI1CEOpOWMX0VEIULso'
  : 'AIzaSyC23l-4JKofQV5hacePzOv5gY54C_Dapf8';

export const TEST = typeof process !== 'undefined' && process.env.NODE_ENV === 'test' && import.meta.env?.DEV;
export const accessEmail = 'wiki@youneedawiki.com';

export const defaultScope =
  'openid email profile https://www.googleapis.com/auth/documents https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/contacts.readonly';

/* 
  Note: eslint-plugin-no-compound-dev checks against the name `DEV`
  If you change the name, update the plugin
*/
export const DEV = import.meta.env?.DEV; // Workers doesn't support for import.meta.env
export const LOCAL = hasWindow && window.location.hostname === 'localhost';

export const LEGACY = !isBun && import.meta.env?.VITE_LEGACY === 'true';
export const baseURL = `${DEV ? 'http://localhost:3000/api' : `${LEGACY ? '/api/v2' : '/api'}`}`;

export const mimeTypes = {
  folder: 'application/vnd.google-apps.folder',
  shortcut: 'application/vnd.google-apps.shortcut',
  document: 'application/vnd.google-apps.document',
  drawing: 'application/vnd.google-apps.drawing',
  spreadsheet: 'application/vnd.google-apps.spreadsheet',
  presentation: 'application/vnd.google-apps.presentation',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  form: 'application/vnd.google-apps.form',
  pdf: 'application/pdf',
  mp4: 'video/mp4',
  quicktime: 'video/quicktime',
  markdown: 'text/markdown',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  numbers: 'application/x-iwork-numbers-sffnumbers',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  text: 'text/plain',
  html: 'text/html',
  png: 'image/png',
  jpg: 'image/jpeg',
  photoshop: 'image/vnd.adobe.photoshop',
  postscript: 'application/postscript',
  svg: 'image/svg+xml',
  octetStream: 'application/octet-stream',
  json: 'application/json',
  // https://stackoverflow.com/questions/1454777/apple-iwork-mime-types
  pages: 'application/x-iwork-pages-sffpages',
} satisfies Record<string, DriveMimeType>;

export type DriveMimeTypes = typeof mimeTypes;

// https://developers.google.com/drive/api/guides/ref-export-formats
export const exportFormats = {
  document: 'text/html',
  spreadsheet: 'text/csv',
  presentation: 'text/plain',
  text: 'text/plain',
  docx: 'text/html',
  xlsx: 'text/html',
  pdf: 'text/plain',
  pptx: 'text/plain',
  html: 'text/html',
} satisfies Partial<Record<keyof typeof mimeTypes, ExportMimeType>>;

export const newFileId = 'new';

// When you update this, update DriveAPI.ts: type = BaseFile
const allFields = import.meta.env?.DEV && hasWindow && /(\?|&)fields/.test(window.location.search);
// If you make changes here, be sure to update server/constants.ts
export const fileFields = allFields
  ? '*'
  : 'id,name,mimeType,shortcutDetails,explicitlyTrashed,parents,webViewLink,webContentLink,createdTime,modifiedTime,owners(displayName,photoLink),ownedByMe,permissions(id,displayName,photoLink,role,type,emailAddress,deleted),lastModifyingUser(displayName,photoLink,me),properties,capabilities(canEdit,canDelete),iconLink,size,thumbnailLink,imageMediaMetadata/width,imageMediaMetadata/height,size';

// You can get 1000 entries with these fields
// If you include parents = 460
// If you include permissions = 100
const unrestrictedFields =
  'id,name,mimeType,shortcutDetails,webViewLink,webContentLink,createdTime,modifiedTime,owners(displayName,photoLink),ownedByMe,lastModifyingUser(displayName,photoLink,me),properties,capabilities(canEdit,canDelete),iconLink,size,thumbnailLink,imageMediaMetadata/width,imageMediaMetadata/height,size';
export const listFields = allFields ? 'files(*)' : `files(${unrestrictedFields},parents)`;

const userAgentData =
  typeof navigator !== 'undefined' && 'userAgentData' in navigator
    ? (navigator.userAgentData as {
        platform: 'macOS';
      })
    : undefined;

// Testing
// export const platform = 'Windows';
export const platform =
  typeof navigator === 'undefined'
    ? ''
    : userAgentData
      ? userAgentData.platform
      : navigator.userAgent.includes('Mac OS')
        ? 'macOS'
        : navigator.userAgent.includes('Windows')
          ? 'Windows'
          : navigator.userAgent.includes('Linux')
            ? 'Linux'
            : '';

export const debug = hasWindow ? gup('debug') : false;
export const expiredToken = hasWindow ? gup('expired') : false;
export const requestPerRoute = hasWindow ? gup('request') : false;

export let isMobile = (() => {
  if (!hasWindow) return false;
  return window.innerWidth < 568;
})();
if (hasWindow) {
  window.matchMedia('(max-width: 568px)').addEventListener('change', (e) => {
    isMobile = e.matches;
  });
}

export const maxDepth = isBun ? 100 : 1000;

export const isIOS = hasWindow && /iPad|iPhone|iPod/.test(navigator.userAgent);
