// Not comprehensive, just all the ones on a macOS keyboard and one's I've encountered
// Converting via regex is significantly faster than using a dom parser
// Falls back to dom parser if it doesn't match
export const entityMap: Record<string, string> = {
  '&amp;': '&',
  '&quot;': '"',
  '&lt;': '<',
  '&gt;': '>',
  '&apos;': "'",
  '&rsquo;': "'",
  '&lsquo;': "'",
  '&ldquo;': '"',
  '&rdquo;': '"',
  '&mdash;': '-',
  '&ndash;': '-',
  '&hellip;': '...',
  '&copy;': '©',
  '&reg;': '®',
  '&trade;': '™',
  '&cent;': '¢',
  '&pound;': '£',
  '&yen;': '¥',
  '&euro;': '€',
  '&sect;': '§',
  '&para;': '¶',
  '&iuml;': 'ï',
  '&ne;': '≠',
  '&le;': '≤',
  '&ge;': '≥',
  '&times;': '×',
  '&divide;': '÷',
  '&plusmn;': '±',
  '&deg;': '°',
  '&sup2;': '²',
  '&sup3;': '³',
  '&frac12;': '½',
  '&frac14;': '¼',
  '&frac34;': '¾',
  '&micro;': 'µ',
  '&alpha;': 'α',
  '&beta;': 'β',
  '&gamma;': 'γ',
  '&delta;': 'δ',
  '&epsilon;': 'ε',
  '&theta;': 'θ',
  '&lambda;': 'λ',
  '&mu;': 'μ',
  '&nu;': 'ν',
  '&pi;': 'π',
  '&rho;': 'ρ',
  '&sigma;': 'σ',
  '&tau;': 'τ',
  '&phi;': 'φ',
  '&omega;': 'ω',
  '&dagger;': '†',
  '&Dagger;': '‡',
  '&bull;': '•',
  '&larr;': '←',
  '&uarr;': '↑',
  '&rarr;': '→',
  '&darr;': '↓',
  '&harr;': '↔',
  '&crarr;': '↵',
  '&lceil;': '⌈',
  '&rceil;': '⌉',
  '&lfloor;': '⌊',
  '&rfloor;': '⌋',
  '&loz;': '◊',
  '&spades;': '♠',
  '&clubs;': '♣',
  '&hearts;': '♥',
  '&diams;': '♦',
  '&OElig;': 'Œ',
  '&oelig;': 'œ',
  '&Scaron;': 'Š',
  '&scaron;': 'š',
  '&Yuml;': 'Ÿ',
  '&circ;': '^',
  '&tilde;': '~',
  '&iexcl;': '¡',
  '&infin;': '∞',
  '&ordf;': 'ª',
  '&ordm;': 'º',
  '&sum;': '∑',
  '&acute;': '´',
  '&uml;': '¨',
  '&oslash;': 'ø',
  '&laquo;': '«',
  '&aring;': 'å',
  '&szlig;': 'ß',
  '&part;': '∂',
  '&fnof;': 'ƒ',
  '&dot;': '˙',
  '&Delta;': '∆',
  '&not;': '¬',
  '&aelig;': 'æ',
  '&Omega;': 'Ω',
  '&asymp;': '≈',
  '&ccedil;': 'ç',
  '&radic;': '√',
  '&int;': '∫',
  '&Agrave;': 'À',
  '&Aacute;': 'Á',
  '&Acirc;': 'Â',
  '&Atilde;': 'Ã',
  '&Auml;': 'Ä',
  '&Aring;': 'Å',
  '&agrave;': 'à',
  '&aacute;': 'á',
  '&acirc;': 'â',
  '&atilde;': 'ã',
  '&auml;': 'ä',
  '&AElig;': 'Æ',
  '&Ccedil;': 'Ç',
  '&Egrave;': 'È',
  '&Eacute;': 'É',
  '&Ecirc;': 'Ê',
  '&Euml;': 'Ë',
  '&egrave;': 'è',
  '&eacute;': 'é',
  '&ecirc;': 'ê',
  '&euml;': 'ë',
  '&Igrave;': 'Ì',
  '&Iacute;': 'Í',
  '&Icirc;': 'Î',
  '&Iuml;': 'Ï',
  '&igrave;': 'ì',
  '&iacute;': 'í',
  '&icirc;': 'î',
  '&Ntilde;': 'Ñ',
  '&ntilde;': 'ñ',
  '&Ograve;': 'Ò',
  '&Oacute;': 'Ó',
  '&Ocirc;': 'Ô',
  '&Otilde;': 'Õ',
  '&Ouml;': 'Ö',
  '&ograve;': 'ò',
  '&oacute;': 'ó',
  '&ocirc;': 'ô',
  '&otilde;': 'õ',
  '&ouml;': 'ö',
  '&Oslash;': 'Ø',
  '&Ugrave;': 'Ù',
  '&Uacute;': 'Ú',
  '&Ucirc;': 'Û',
  '&Uuml;': 'Ü',
  '&ugrave;': 'ù',
  '&uacute;': 'ú',
  '&ucirc;': 'û',
  '&uuml;': 'ü',
  '&Yacute;': 'Ý',
  '&yacute;': 'ý',
  '&yuml;': 'ÿ',
  '&middot;': '·',
  '&sdot;': '⋅',
  '&oplus;': '⊕',
  '&zwnj;': '',
};
