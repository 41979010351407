import { ChevronRight, Home } from '@mui/icons-material';
import { Fragment, useRef } from 'react';
import { Link } from 'wouter';
import { type DriveFile } from '~/utils/DriveAPI';
import { LEGACY, newFileId } from '~/utils/constants';
import { isMarkdownLink } from '~/utils/isMarkdownLink';
import * as styles from './Breadcrumbs.css';

const getBreadcrumbs = (file: DriveFile, wiki: DriveFile, files: DriveFile[]): DriveFile[] => {
  if (file.id === wiki.id) return [wiki];
  if (file.parents?.[0] === wiki.id) return [wiki, file];
  const parent = files.find((o) => o.id === file.parents?.[0]);
  if (!parent) return [];
  return [...getBreadcrumbs(parent, wiki, files), file];
};

const Breadcrumbs = ({ files, file, wiki }: { files: DriveFile[]; file: DriveFile; wiki: DriveFile }) => {
  const newFileRef = useRef<DriveFile>();
  if (file.id === wiki.id) return null;

  const allBreadcrumbs = getBreadcrumbs(file, wiki, files);
  const home = allBreadcrumbs.at(0);
  if (!home) return null;
  const breadcrumbs = allBreadcrumbs.length > 3 ? [home].concat(allBreadcrumbs.slice(1).slice(-2)) : allBreadcrumbs;

  // Avoid updating the breadcrumb when a new file is created
  const isNewFile = file.id === newFileId || file.name === newFileRef.current?.name;
  if (isNewFile) {
    newFileRef.current = file;
  } else if (newFileRef.current) {
    newFileRef.current = undefined;
  }

  return (
    <div className={styles.breadcrumbs}>
      {breadcrumbs
        .map((o) => {
          const markdown = isMarkdownLink(o.name);
          return markdown
            ? {
                ...o,
                name: markdown.name,
              }
            : o;
        })
        .map((item, i) => {
          const legacyTo = LEGACY ? `/app/page/${item.id}?p=${wiki.id}` : '';
          const id = isNewFile && item.id === file.id ? file.name : item.id;
          return (
            <Fragment key={id}>
              {/* Ellipsis */}
              {i === 1 && breadcrumbs.length !== allBreadcrumbs.length && (
                <Fragment>
                  <span className={styles.breadcrumb} aria-label="breadcrumb">
                    ...
                  </span>
                  <span className={styles.chevron}>
                    <ChevronRight />
                  </span>
                </Fragment>
              )}

              {/* Home or parent link */}
              {i !== breadcrumbs.length - 1 && (
                <Link
                  className={styles.breadcrumb}
                  to={LEGACY ? legacyTo : `/app/page/${wiki.id}/${item.id}`}
                  {...(LEGACY && {
                    onClick: () => {
                      window.legacyHistory?.push(legacyTo);
                    },
                  })}
                  aria-label="breadcrumb"
                >
                  {i === 0 ? <Home className={styles.homeIcon} /> : <span>{item.name}</span>}
                </Link>
              )}

              {/* Chevrons */}
              {i !== breadcrumbs.length - 1 && (
                <span className={styles.chevron}>
                  <ChevronRight />
                </span>
              )}

              {/* Page name */}
              {i === breadcrumbs.length - 1 && (
                <div className={styles.pageLabel} aria-label="breadcrumb">
                  {item.name}
                </div>
              )}
            </Fragment>
          );
        })}
    </div>
  );
};

export default Breadcrumbs;
