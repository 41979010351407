import dedent from 'dedent';
import * as app from '~/app.css';
import * as styles from '~/components/Page/Page.css';
import { LoomPlayButton } from '~/elements/icons';
import { DEV, isBun, mimeTypes } from '~/utils/constants';
import { colorIsDark, getMimeType, getTextFromHTML } from '~/utils/utils';
import { type DriveFile } from './DriveAPI';

export const openInNew = /* html */ `<svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" style="width: 1em;height: 1em; margin: -3px 0 0 1px; vertical-align: middle"><path fill="currentColor" d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path></svg>`;

export function decodeHTML(html: string) {
  // HTML entities parser (Bun only)
  // https://stackoverflow.com/a/44195856/1845423
  if (isBun && !import.meta.env?.PROD) {
    const translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    const translate = {
      nbsp: ' ',
      amp: '&',
      quot: '"',
      lt: '<',
      gt: '>',
    } as const;

    return html
      .replace(translate_re, (_, entity: keyof typeof translate) => translate[entity])
      .replace(/&#(\d+);/g, (_, numStr: string) => {
        const num = parseInt(numStr);
        return String.fromCharCode(num);
      });
  }

  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

export const parseWordDoc = (data: string): string => {
  // Handle dark mode text for word docs
  // Remove all colors that start with a number
  return data.replaceAll(/color:#\d\w{2,5};/g, '');
};

const getIframe = ({ src, style, width, height }: { src: string; style: string; width: string; height: string }) => {
  const isLoom = src.startsWith('https://www.loom.com');
  const setVisibility = `this.className='${isLoom ? app.fadeInDelay : app.fadeIn}'; this.style.visibility='visible'; setTimeout(() => { this.previousElementSibling.remove()}, 600);`;

  // Custom embed for loom
  // Shows the image and play button immediately
  if (isLoom) {
    // https://dev.loom.com/docs/embed-sdk/getting-started
    // https://www.loom.com/v1/oembed?url=https://www.loom.com/share/0281766fa2d04bb788eaf19e65135184
    const thumbnail = src.startsWith('https://www.loom.com')
      ? `https://cdn.loom.com/sessions/thumbnails/${src.split('/').pop()}-00001.jpg`
      : '';
    return dedent /* html */`
    <div style="position: relative">
      <div style="position: absolute; ${width ? `width:${width};` : ''} ${height ? `height: ${height};` : ''}">
        <a href="${src.replace('embed', 'share')}" target="_blank" style="width: 100%; height: 100%; position: relative; display: block; text-decoration: none;">
          <img src="${thumbnail}" style="width: 100%; height: 100%" />
          <div style="position: absolute; top: 0; right: 0; bottom: 0; left: 0; margin: auto; background-color: rgba(51, 51, 51, 0.1)"></div>
          ${LoomPlayButton}
        </a>
      </div>
      <iframe src="${src}" onLoad="${setVisibility}" style="${style}" width="${width}" height="${height}" frameborder="0" allowfullscreen sandbox="allow-scripts allow-forms allow-presentation allow-same-origin allow-popups"></iframe>
    </div>`.replace(/\n\s*/g, '');
  }

  return dedent /* html */`
    <div style="position: relative">
      <div style="position: absolute; ${width ? `width:${width};` : ''} ${height ? `height: ${height};` : ''}">
        <div data-react="SkeletonLoader"></div>
      </div>
      <iframe src="${src}" onLoad="${setVisibility}" style="${style}" width="${width}" height="${height}" frameborder="0" allowfullscreen sandbox="allow-scripts allow-forms allow-presentation allow-same-origin allow-popups"></iframe>
    </div>`.replace(/\n\s*/g, '');
};

export const modal =
  isBun || typeof HTMLDialogElement === 'function'
    ? dedent /* html */`
  <dialog id="dialog" style="padding: 0; border: 0;" onclick="window.event.target === window.event.currentTarget && this.close()">
    <div style="padding: 1.8rem 1rem .8rem 1rem; border: 1px solid #a9a9a9">
      <img id="dialog-src" />
      <form method="dialog" style="display: flex;justify-content: flex-end;padding-bottom: 0.5rem;position: absolute;right: 1px; top: 1px;">
        <button style="outline: 0; cursor: pointer; color: #696969; border: 0;border-radius: 3px;font-family: 'Lato';width: 20px;height: 20px;margin: 0;padding: 0;">&#x2715</button>
      </form>
    </div>
  </dialog>`
    : '';

export const modalClick = (src: string) =>
  isBun || typeof HTMLDialogElement === 'function'
    ? `onclick="document.getElementById('dialog-src').src='${src}'; document.getElementById('dialog').showModal()"`
    : '';

export const parseExport = (
  html: string,
  file: DriveFile | undefined,
  files: DriveFile[],
  wiki: DriveFile | undefined
): string => {
  // log.debug('before:', html);
  // return html;

  // if (import.meta.env?.DEV && gup('debug')) {
  //   return html;
  // }

  const mimeType = getMimeType(file);
  if (mimeType === mimeTypes.spreadsheet) {
    return html;
  }

  let result = html
    .replace('<html>', '')
    // .replace(/[\s\S]*<\/head>/, '')
    .replace('</html>', '')
    .replace(/<meta[^>]*>/, '')
    .replace(/( style="[^"]*);color:#000000;/g, '$1;')
    .replace(/(style=")color:#000000;/g, '$1')

    // Remove empty first divs
    .replace(/(<body[^>]*>)<div>(<p[^>]*><span[^>]*><\/span><\/p>)+<\/div>/, '$1')

    // Detect dark text
    // eslint-disable-next-line
    .replace(/ style="[^"]*color:(#\w+)[^"]*"/g, (str, color: string) => {
      const isDark = colorIsDark(color);
      return str + (isDark ? ' data-is-dark="true"' : '');
    })
    // Detect dark background
    // eslint-disable-next-line
    .replace(/ style="[^"]*background-color:(#\w+)[^"]*"/g, (str, color: string) => {
      const isLight = !colorIsDark(color);
      return str + (isLight ? ' data-is-light-bg="true"' : '');
    })
    // Replace 11pt font size
    // .replace(/( style="[^"]*)font-size:11pt;/g, '$1')

    // .replace(/(<span[^>]*)font-size:11pt/g, '$1font-size:1rem') // Set default font size
    .replace(/(<body[^>]*><h\d[^>]*padding-top:)\d+/, '$10') // Remove padding from headings
    .replace(
      /(<div )(style="border:1px solid black;margin:5px")(><p[^<]*<a href="#cmnt_[^>]*>)/g,
      `$1class="page-comment" $3`
    )
    // Replace Arial
    .replace(/( style="[^"]*)font-family:&quot;Arial&quot;;/g, '$1')

    // Replace unused, default styles in text
    // .replace(/<\w[^>]*/g, (str) => {
    .replace(/<(span|p|li)[^>]*/g, (str) => {
      return str.replace(
        /(style=")*padding:0;|text-align:left|orphans:2;|widows:2;|text-decoration:none;|vertical-align:baseline;|font-style:normal|font-size:11pt;/g,
        '$1'
      );
    })
    // Remove default font-weight for p|span
    .replace(/<p[^>]*><span[^>]*>/g, (str) => {
      return str.replace(/(style="[^"]*)font-weight:400;/g, '$1');
    })
    // Format span without style
    .replace(/<span\s>/g, '<span>')

    // span text ending with a <br> should break the line before a list
    // http://localhost:3000/app/page/1NqCSiMuEfPfaHTumR_rX6J8zo7ygjx8q/13lHEZncPNbtILiOcQGg6YhRWUlrHEyb_uLlXN6DnWJA
    .replace(/<p[^>]*><span[^>]*>([^<]*<br>)<\/span><\/p><[ou]l/g, (str, text: string) => {
      return str.replace(text, `${text}<br>`);
    })

    // Bump up font size 10%
    // Bump up text-indent 10%
    .replace(/<\w[^>]*/g, (str) => {
      return str
        .replace(/(style="[^"]*)font-size:(\d+)pt;/g, (str, start: string, size: string) => {
          if (size) {
            return `${start}font-size:${Number(size) * 1.1}pt;`;
          }
          return str;
        })
        .replace(/(style="[^"]*)text-indent:(\d+)pt;/g, (str, start: string, size: string) => {
          if (size) {
            return `${start}text-indent:${Math.round(Number(size) * 1.1)}pt;`;
          }
          return str;
        });
    })

    // Disabled
    // Some lists have items outside the list that are also aligned
    // http://localhost:3000/app/page/1NqCSiMuEfPfaHTumR_rX6J8zo7ygjx8q/1ylZJQs1Iic8WxU6bmC6bJj8yRVJ7WOSyOEEelGG3v48
    // // Bump up margin-left 10% for lists
    // .replace(/<li[^>]*/g, (str) => {
    //   return str.replace(/(style="[^"]*)margin-left:(\d+)pt;/g, (str, start: string, size: string) => {
    //     if (size) {
    //       return `${start}margin-left:${Math.round(Number(size) * 1.1)}pt;`;
    //     }
    //     return str;
    //   });
    // })
    // Bump up td width 10%
    .replace(/<td[^>]*/g, (str) => {
      return str.replace(/(style="[^"]*)width:(\d+)pt;/g, (str, start: string, size: string) => {
        if (size) {
          return `${start}width:${Math.round(Number(size) * 1.1)}pt;`;
        }
        return str;
      });
    })

    // Add padding to bottom of headings
    .replace(/<h\d[^>]*>/g, (str) => {
      return str.replace(/(style="[^"]*)padding-bottom:(\d+)pt;/g, (str, start: string, size: string) => {
        if (size) {
          return `${start}padding-bottom:${Number(size) + 2}pt;`;
        }
        return str;
      });
    })

    // .replace(/<p[^>]*><span[^>]*/g, (str) => {
    //   return str.replace(
    //     /(style=")*font-family:&quot;Arial&quot;;|font-weight:400;|padding:0;|text-align:left|line-height:1.15;|orphans:2;|widows:2;|text-decoration:none;|vertical-align:baseline;|font-style:normal|font-size:11pt;/g,
    //     '$1'
    //   );
    // })
    // Replace default link color
    .replace(/<span[^>]*><a[^>]*>/g, (str) => {
      if (/color:#1155cc/.test(str)) {
        return str.replace(/(style=")*color:#1155cc/g, '$1').replace(/(style=")*color:inherit/g, '$1');
      }
      return str;
    })
    // Image overlay
    // .replace(
    //   /<span[^>]*>(<img[^>]*src=")([^"]*)("[^>]*>)<\/span>/,
    //   (str: string, _start: string, src: string, _end: string) => {
    //     const style = str.match('style="([^"]*)"')?.[1] ?? '';
    //     const props: ImageOverlayProps = {
    //       src,
    //       style,
    //     };
    //     return `<span data-react='ImageOverlay' data-props='${JSON.stringify(props)}'></span>`;
    //   }
    // )

    // Inject heading links
    .replace(/<h\d[^>]*id="([^"]*)"[^>]*>[\s\S]*?<\/h\d>/g, (str, id: string) => {
      const endOfHeadingIndex = str.indexOf('>');
      const endIndex = str.indexOf('</h');
      // const headingStyle = str.match(/<h\d[^>]*style="([^"]*)"/)?.[1] ?? '';
      // const headingColor = headingStyle.match(/color:([^;]*);/)?.[1] ?? '';

      // If it does not have any text content, don't add a link (images)
      const headingContent = str.slice(endOfHeadingIndex + 1, endIndex);
      const textContent = getTextFromHTML(headingContent);
      if (!textContent) {
        return str;
      }

      const textColor = headingContent.match(/^<span[^>]*color:([^;]*);/)?.[1] ?? '';

      const hashLink = /*html*/ `<a href="#${id}" ${
        textColor ? `style="color:${textColor}"` : 'style="color:inherit"'
      } class="${styles.pageHashLink}" aria-hidden="true">#</a>`;

      // Replace <span> with <a>
      // .replace(/^<span/, `<a href="#${id}" class="${pageHeadingLink}"`)
      // .replace(/<\/span>$/, '</a>');

      return `${str.slice(0, endOfHeadingIndex)} class="${
        styles.pageHeading
      }"><span style="position:relative; display: inline-block">${headingContent} ${hashLink}</span>${str.slice(
        endIndex
      )}`;
    })

    // --- Images ---
    // Replaces display: inline-block from surrounding span with display: flex
    // http://localhost:3000/app/page/1Z8jdSOSICqS0NSYX3EBZWJmcSEGPE4Dj/1NfuIguBj1IzmjjxeAN-3inUlvriSYahO3kISMKiJ04w
    .replace(/<li((?!<\/li>).)*/g, (a) => {
      if (!a.includes('<img')) return a;
      return a.replace(/<span[^>]*><img/, (a) => a.replace(/display:\s?inline-block;?/, 'display: flex;'));
    })

    // Remove empty style attributes
    .replace(/\s?style=""/g, '')

    // Remove empty spans
    .replace(/(<p[^>]*>)<span[^>]*><\/span>/g, '$1')

    // Convert <br> at the end of lists to display block
    // Don't use <div> because a <div> inside a <p> is invalid
    // Testing: http://localhost:3000/app/page/1nBSr8AxTNwiuQbugrjcILqhC3c-3YgKFCBm8iw0yol4
    // http://localhost:3000/app/page/16Gs1rRlLKNUsSEJ3RP9qKiFvIuH1xScT/16xP4snmp7_ITZh9mC10wVP_ReOFEpVd_xQ23Na2JVIY
    .replace(/<br><\/span><\/li>/g, /*html*/ `<span style="display: block"><br></span></span></li>`)

    // Add additional spacing to tabs
    .replace(/(<span[^>]*>)((&nbsp;){8}[^>]*)<\/span>/g, (_, span: string, content: string) => {
      const spanStr = span.includes('style="')
        ? span.replace(/style="([^"]*)"/, (_, style: string) => {
            return `style="${style};margin-left:.3ch"`;
          })
        : `${span.slice(0, -1)} style="margin-left:.3ch">`;

      return `${spanStr}&nbsp;&nbsp;&nbsp;&nbsp;${content}</span>`;
    })

    // Formatting for small and large images
    .replace(/(<span[^>]*>)(<img[^>]*>)/g, (str, span: string, img: string) => {
      const width = span.match(/width:\s([\d.]+)px;/)?.[1];
      if (!width) return str;
      if (Number(width) > 708) {
        return (
          span.replace(/(style="[^"]*)width:\s[\d.]+px/g, '$1width:auto') + img.replace(/\s/, ' data-size="large" ')
        );
      }
      return span + img.replace(/\s/, ' data-size="small" ');
    })

    // Formatting for images
    // http://localhost:3000/app/page/1Z8jdSOSICqS0NSYX3EBZWJmcSEGPE4Dj/1gUmFTXFpmfyggvkngH75wW5K9SmJrhIZKT6A7Sxw8RI
    .replace(/(<span[^>]*>)(<img[^>]*>)/g, (_, span: string, img: string) => {
      // Remove margin from img
      const imgStr = img.replace(/margin-\w+:\s[^;]+;/, '');

      return span + imgStr;
    })
    // --- End of images ---

    // Fix bullet point sizing
    .replace(/<style type="text\/css">[^<]*<\/style>/, (str) => {
      return str.replace(
        /(li:before\{content:"[●○■]\s*")\}/g,
        '$1; font-size: .55rem; display: inline-flex; align-items: center; height: 100%}'
      );
    })

    // Re-size bullet points
    .replace(/&#9679;/g, /*html*/ `<span style="font-size: .55rem; vertical-align: middle;">&#9679;</span>`)

    // remove -ve margin-top from images
    // .replace(/(<img[^>]*style="[^"]*)margin-top:\s-[\d.]+px;/g, '$1')

    // // Replace width with max-width: 100% for images in spans
    // .replace(/(<span[^>]*>)(<img[^>]*>)/g, (_, span: string, img: string) => {
    //   return (
    //     span +
    //     img
    //       .replace(/(style="[^"]*)width:\s[\d.]+px;/g, '$1max-width:100%;')
    //       .replace(/(style="[^"]*)height:\s[\d.]+px;/g, '$1')
    //       .replace(
    //         /margin-left: 0\.00px;|transform: rotate\(0\.00rad\) translateZ\(0px\);|-webkit-transform: rotate\(0\.00rad\) translateZ\(0px\);/g,
    //         ''
    //       )
    //   );
    // })

    // Fix list left padding
    // .replace(/(<li[\s\S]+?)padding-left:0pt;/g, (_, b: string) => {
    //   return b + 'padding-left:12.5px;position:relative;';
    // })
    // .replaceAll(`li:before{content:"●  ";`, 'li:before{content:"● "; position: absolute; left: -11.5px; top: 1px;')

    // --- Legacy codebase ---
    // Disabled
    // http://localhost:3000/app/page/1NqCSiMuEfPfaHTumR_rX6J8zo7ygjx8q/1ylZJQs1Iic8WxU6bmC6bJj8yRVJ7WOSyOEEelGG3v48
    // Fix list left margin
    // .replace(
    //   /(<li[\s\S]+?)(margin-left:)(\d+\.?\d*|\.\d+)(\w+)/g,
    //   (_, b: string, c: string, d: string, e: string) => {
    //     // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    //     return b + c + (Number(d) - 15) + e;
    //   }
    // )
    // Fixes line-height (<p> only)
    .replace(
      // eslint-disable-next-line
      /<(\w+)[^>]*line-height:(\d+\.?\d*|\.\d+)/g,
      (str, tag: string, a: string) => {
        return tag === 'p'
          ? str.replace(/line-height:(\d+\.?\d*|\.\d+)/, `line-height:${(Number(a) * 1.3).toFixed(1)}`)
          : str.replace(/line-height:(\d+\.?\d*|\.\d+)/, `line-height:${(Number(a) * 1.18).toFixed(2)}`);
      }
    )

    // Fixes line-height (original)
    // .replace(/(line-height:)(\d+\.?\d*|\.\d+)/g, (_, a: string, b: string) => {
    //   return a + (Number(b) * 1.18).toFixed(2);
    // })

    // Fixes line height at end of list
    // See: http://localhost:3000/app/page/0B3trZiSEz7IqYkxReU0yVFNzVEU/1f3JG20gz15fEYukKrefCMM5MHfaNGlbXyUZqKQuweqo
    .replace(/<li((?!<\/li>).)*<\/li><\/ul>/, (a) => {
      const liStyle = a.match(/<li\sstyle="([^"]+)"/)?.[1];
      const paddingBottom = liStyle?.match(/padding-bottom:(\d+pt;?)/)?.[1];
      if (paddingBottom) {
        return a
          .replace(/margin-bottom:\d;/, '')
          .replace(/padding-bottom:\d+pt;?/, '')
          .replace(/(<li\sstyle="[^"]*)/, `$1;margin-bottom:${paddingBottom}`);
      }
      return a;
    })
    .replace(/(<li\sstyle="[^>]*)padding-top:\d+pt;/g, '$1')
    .replace(/(<li\sstyle="[^>]+)padding-bottom:\d+pt;/g, '$1')

    // Remove -ve margin-left from tables (pageless)
    .replace(/(<table style=")(margin-left:-[\d.]+pt;)/g, '$1')
    // --- End of legacy codebase ---

    // Add no-referrer for images in development
    // to fix rate limiting error
    // https://stackoverflow.com/a/41884494/1845423
    .replace(/<img/g, (str) => {
      if (DEV) return str.replace(/<img/g, `<img referrerpolicy="no-referrer"`);
      return str;
    })

    // Video
    .replace(/&lt;video[^/]*src((?!video).)*\/video&gt;/g, (a) => {
      const html = decodeHTML(a);
      const src = html.match(/src="([^"]*)"/)?.[1];
      const width = html.match(/width="([^"]*)"/)?.[1] ?? '100%';
      const height = html.match(/height="([^"]*)"/)?.[1] ?? '100%';
      if (!src) return a;

      const style = html.match(/video[^>]*style="([^"]*)"/)?.[1] ?? '';

      return /* html */ `
            <video controls style="${style}" width="${width}" height="${height}">
              <source src="${src}">
            </video>
          `;
    })

    // Drive video
    .replace(
      /&lt;iframe[^/]*src=&quot;https:\/\/drive.google.com\/file\/d\/(\w+)\/preview&quot;[^/]*\/iframe&gt;/g,
      (_, id: string) => {
        return /* html */ `
            <video controls style="max-width: 100%">
              <source src="https://drive.google.com/uc?export=download&id=${id}">
            </video>
          `;
      }
    )

    // Loom embed <div><iframe></iframe></div>
    // We use ((?!&gt).)* instead of .* to avoid injections
    // http://localhost:3000/app/page/17715VfJmL3JHx90XD8KsuZH9yz4dGP55/12hXQvzYvDhdUNnxeyKNXV2cCWZXtmylwRvz8YwfBISQ
    .replace(/(&lt;div((?!&gt).)*&gt;)&lt;iframe((?!&gt).)*&gt;&lt;\/iframe&gt;(&lt;\/div&gt;)/g, (a) => {
      const html = decodeHTML(a);
      const src = html.match(/src="([^"]*)"/)?.[1];
      const width = html.match(/width="([^"]*)"/)?.[1] ?? '100%';
      const height = html.match(/height="([^"]*)"/)?.[1] ?? '100%';
      if (!src) return a;

      const containerStyle = html.match(/div[^>]*style="([^"]*)"/)?.[1] ?? '';
      const s = html.match(/iframe[^>]*style="([^"]*)"/)?.[1] ?? '';
      const style = s ? `${s.trimEnd().endsWith(';') ? s : s + ';'} visibility: hidden` : 'visibility: hidden';

      const delay = src.startsWith('https://www.loom.com') ? app.fadeInDelay : app.fadeIn;
      const setVisibility = `this.className='${delay}'; this.style.visibility='visible'; setTimeout(() => { this.previousElementSibling.remove()}, 600);`;

      return dedent /* html */`
            <div style="position: relative">
              <div style="${containerStyle ?? ''}">
                <div data-react="SkeletonLoader"></div>
                <iframe src="${src}" onLoad="${setVisibility}" style="${style}" width="${width}" height="${height}" frameborder="0" allowfullscreen sandbox="allow-scripts allow-forms allow-presentation allow-same-origin allow-popups"></iframe>
              </div>
            </div>`.replace(/\n\s*/g, '');
    })

    // iframe embed that has an <a> or <span> tag in the in src
    // https://mail.google.com/mail/u/0/#label/Support%2FEmail/FMfcgzGsmNPzcgstHnFlgbgNjzkWWDWT
    .replace(/(&lt;iframe((?!&gt).)*?src=&quot;)<\/span>[\s\S]*?&lt;\/iframe&gt;/g, (a) => {
      // https://regex101.com/r/9NvTyu/1
      const hrefSrc = (a.match(/&lt;iframe(?:(?!&gt).)*?src=&quot;[\s\S]*?a href=[^>]*>([^<]*)/) || [])[1];

      // https://regex101.com/r/eVTbwW/1
      const altSrc = (a.match(/&lt;iframe(?:(?!&gt).)*?src=&quot;[\s\S]*?(https:\/\/[^*<]*)/) || [])[1];

      const src = (hrefSrc || altSrc) ?? '';

      const html = decodeHTML(a).replace('<iframe ', '');
      const attrs = html.replace(/<[^>]*>/g, '');

      const w = attrs.match(/width="([^"]*)"/)?.[1];
      const h = attrs.match(/height="([^"]*)"/)?.[1];
      const width = w ? `${w}px` : '100%';
      const height = h ? `${h}px` : '100%';
      const s = attrs.match(/style="([^"]*)"/)?.[1];
      const style = s ? `${s.trimEnd().endsWith(';') ? s : s + ';'} visibility: hidden` : 'visibility: hidden';

      const iframe = getIframe({
        src,
        width,
        height,
        style,
      });

      return iframe;
    })

    // General embed <iframe></iframe>
    // General embed <iframe>Loading...</iframe>
    .replace(/&lt;iframe((?!&gt).)*&gt;[^/]*&lt;\/iframe&gt;/g, (a) => {
      const html = decodeHTML(a);
      const src = html.match(/src="([^"]*)"/)?.[1];
      const w = html.match(/width="([^"]*)"/)?.[1];
      const h = html.match(/height="([^"]*)"/)?.[1];
      const width = w ? `${w}px` : '100%';
      const height = h ? `${h}px` : '100%';
      if (!src) return a;

      const s = html.match(/iframe[^>]*style="([^"]*)"/)?.[1] ?? '';
      const style = s ? `${s.trimEnd().endsWith(';') ? s : s + ';'} visibility: hidden` : 'visibility: hidden';

      const iframe = getIframe({
        src,
        width,
        height,
        style,
      });

      return iframe;
    })

    // Video embed: <video><source src="" type="video/mp4"></source></video>
    .replace(
      /(&lt;div((?!&gt).)*&gt;)?&lt;video((?!&gt).)*&gt;&lt;source((?!&gt).)*&gt;&lt;\/source&gt;&lt;\/video&gt;(&lt;\/div&gt;)?/g,
      (a) => {
        return decodeHTML(a);
      }
    )

    // Dropbox image
    // https://regex101.com/r/39156H/1
    .replace(
      /(&lt;img((?!&gt).)*src=&quot;((?!&gt).)*(https:\/\/www\.dropbox\.com\/s\/.*?)&quot;((?!&gt).)*\/&gt;)/g,
      (a) => {
        return a.replace('?dl=0', '?raw=1');
      }
    )
    // Image embedding
    .replace(/(&lt;img((?!&gt).)*src=&quot;((?!&gt).)*&quot;((?!&gt).)*\/&gt;)/g, (a) => {
      const html = decodeHTML(a);
      const src = html.match(/src="([^"]*)"/)?.[1];
      if (!src) return a;
      const alt = html.match(/alt="([^"]*)"/)?.[1] ?? '';

      return `<img src="${src}"${alt ? ` alt="${alt}"` : ''} style="max-width: 100%" />`;
    })

    // Remove &amp and onwards after ?q=
    .replace(/(href="https:\/\/www\.google\.com\/url\?q=[^"&]*)&amp[^"]*/g, '$1')

    // Handle https://www.google.com/url?q= links
    .replace(/(href="https:\/\/www\.google\.com\/url\?q=[^>]*>[^<]*)(<\/a>)/g, (a, b: string, c: string) => {
      if (a.includes('?external') || a.includes('%26external'))
        return a.replace(/(href=")https:\/\/www.google.com\/url\?q=/g, '$1');

      const pageLink = b
        // Fix links
        .replace(/(href=")https:\/\/www.google.com\/url\?q=/g, '$1')
        .replace(/href="https:\/\/drive\.google\.com\/file\/d\//g, 'href="/app/page/')
        .replace(/href="https:\/\/drive\.google\.com\/open\?id%3D/g, 'href="/app/page/')
        // Replace /edit
        .replace(/(href="https:\/\/docs\.google\.com[^"]+)\/edit[^"]*/g, '$1')
        // Replace docs.google.com
        .replace(/href="https:\/\/docs\.google\.com\/[^"]+\/d\//g, 'href="/app/page/');

      // Separate URL for image
      // const documentURL = b.match(/href="([^"]*)"/)?.[1]?.replace('https://www.google.com/url?q=', '');
      // return pageLink + c + (documentURL ? /* html */ `<a target="_blank" href="${documentURL}">${img}</a>` : img);

      // Document icon
      // if (b.includes(editURLs[mimeTypes.document].replace(/\/d\/$/, ''))) {
      //   return pageLink + img + c;
      // }

      const headingId = b.match(/(%23heading%3D)([\w.]+)/)?.[2];
      const link = headingId ? pageLink.replace(/(href="[^"]*)/, `$1#${headingId}`) : pageLink;
      return link + c;
    })

    // Replace /edit from docs.google.com
    .replace(/(href="https:\/\/docs\.google\.com[^"]+)\/edit[^"]*/g, (a, b: string) => {
      if (a.includes('?external') || a.includes('%26external')) return a;
      return b;
    })

    // Replace docs.google.com links, but keeps headingId
    .replace(/href="https:\/\/docs\.google\.com\/[^"]+\/d\/([^"]+)/g, (a, b: string) => {
      if (a.includes('?external') || a.includes('%26external'))
        return a.replace('?external', '').replace('%26external', '');
      return 'href="/app/page/' + b;
    })
    // Replace folder
    .replace(/href="https:\/\/drive\.google\.com\/drive\/folders\//g, 'href="/app/page/')

    // For any full URL's remaining add a target="_blank"
    // For any full URL's remaining add a OpenInNew icon
    .replace(/(href="https?:\/\/[^>]*>[^<]*)(<\/a>)/g, (_, a: string, b: string) => {
      return 'target="_blank" ' + a + openInNew + b;
    })

    // For any mailto links, add a target="_blank"
    .replace(/(href="mailto:[^>]*>[^<]*)(<\/a>)/g, (_, a: string, b: string) => {
      return 'target="_blank" ' + a + openInNew + b;
    })

    .replaceAll('/view?usp%3Ddrivesdk', '')

    // It would be cool to add a thumbnail preview when hovering
    // https://stackoverflow.com/questions/25648388/permanent-links-to-thumbnails-in-google-drive-api

    // --- URL formatting ---
    // Remove gid
    .replace(/%23gid%3D\d+/g, '')
    // Fixes links with encoded values
    // %23, %3D become # and =
    // E.g: https://drive.google.com/open?id%3D1gZmLgJLeOn5XyvIKCU-Rmb_8X1JDiU_Eck_dsFolfr8
    // E.g: https://community.atlassian.com/t5/Confluence-questions/Migrating-Data-from-confluence-to-google-Drive/qaq-p/1297000%23M198021
    .replace(/(href=")(https?:\/\/[^"\s]*)/g, (_, b: string, c: string) => {
      const result = b + decodeURIComponent(c);
      return result.replace('?usp=sharing', '');
    })

    // Numbered list is not bold
    .replace(/(<li[^>]*style=")([^>]*><span[^>]*font-weight:700;[^>]*>[^<]*<\/span><\/li>)/, '$1font-weight:700;$2')

    // --- links ---
    // Remove color inherit from links
    .replace(/(<a[^>]*style="[^"]*)color:inherit;?/g, '$1')

    // Replace themes.googlusercontent.com with proxy request
    // .replace(/@import url\(https:\/\/themes\.googleusercontent\.com\/fonts\/css[^)]*\)/, (a) => {
    //   const url = a.match(/url\(([^)]*)\)/)?.[1] ?? '';
    //   return a.replace(url, `/api/proxy/${encodeURIComponent(url)}`);
    // })

    // Fix Consolas font
    .replace(/(font-family:)&quot;Consolas&quot;/g, '$1SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace')

    // Fix RTL for general tags
    .replace(/(<\w+\sdir="rtl"[^>]+)(text-align:left)/g, '$1')

    // Fix RTL for lists
    .replace(/(<ul)([^>]+><li\sdir="rtl"[^>]+>)/g, '$1 dir="rtl" $2');

  // Rewrite links within this wiki from /app/page/<file.id> to /app/page/<wiki.id>/<file.id>
  if (wiki) {
    result = result.replace(/(href="\/app\/page\/)([^"]+)/g, (_, a: string, b: string) => {
      const file = files.find((f) => f.id === b);
      if (!file) return a + b;
      return `${a}${wiki.id}/${b}`;
    });
  }

  // Modal support
  if (result.includes('<img')) {
    result =
      result.replace(/(<img )([^>]+>)/g, (img, a: string, b: string) => {
        const src = (img.match(/src="([^"]+)/) || [])[1] ?? '';
        return a + modalClick(src) + ' ' + b.replace(/(style="[^"]+)/, '$1;cursor:pointer;');
      }) + modal;
  }

  // Code block that is formatted out of order
  const codeBlockPatternOutOfOrder = /<p[^>]*><span[^>]*>&#60419;[^<]*<\/span>(?:(?!&#60418;).)*&#60418;/g;
  if (codeBlockPatternOutOfOrder.test(result)) {
    result = result.replace(codeBlockPatternOutOfOrder, (a) => {
      const str = a.replace('&#60418;', '');
      const lastClosingParagraph = str.lastIndexOf('</span></p>');
      const output = str.slice(0, lastClosingParagraph) + '&#60418;' + str.slice(lastClosingParagraph, str.length);
      return output;
    });
  }

  // Code block support
  const codeBlockPattern = /<p[^>]*><span[^>]*>&#60419;[^<]*<\/span>(?:(?!&#60418;).)*&#60418;<\/span><\/p>/g;
  if (codeBlockPattern.test(html)) {
    result = result.replace(codeBlockPattern, (a) => {
      const output = a.replace('&#60419;', '').replace('&#60418;', '');
      return /* html */ `
        <div style="background: #f1f3f4; padding: 20px 23px 23px 23px; border-radius: 14px">${output}</div>
      `;
    });
  }

  // log.debug('after:', result);
  return result;
};
